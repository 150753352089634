<template>
  <div>
    <v-dialog
        v-model="visibility"
        max-width="830"
        content-class="cross__dialog"
        :transition="$dialogTransition"
        @click:outside="onCancel()"
        scrollable
    >
      <v-card>
        <v-slide-x-transition>
          <div class="cross__inner">
            <v-btn fab small elevation="0" @click="onCancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-slide-x-transition>
        <v-card-title>
          <timePicker v-if="type !== 'card'" :style="$vuetify.breakpoint.width <= 500 ? { minWidth: '380px', maxWidth: '380px' } : {}" @callTheFuction="fetchTimeLineUser" />
          <v-row no-gutters class="mb-2 mt-4 justify-space-between" align="center">
            <v-col :cols="type === 'card' && $vuetify.breakpoint.width <= 750 ? '12' : '5' ? 5 : 6" class="d-flex align-center pr-5">
              <v-menu
                ref="timeMenu"
                v-model="timeMenu"
                :close-on-content-click="false"
                transition="scale-transition"
                offset-y
                color="orange"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <div >
                    <v-text-field
                      ref="inputMsg"
                      v-model="time"
                      :label="$t('btn.addTime')"
                      prepend-icon="mdi-clock"
                      color="orange"
                      v-bind="attrs"
                      :maxLength="5"
                      v-on="on"
                      :error-messages="timeErrors"
                      @blur="onTimeBlur"
                      @paste="validationForTimePicker($event)"
                      @keypress="validationForTimePicker($event)"
                  ></v-text-field>
                  </div>
                </template>
                <v-time-picker
                  v-if="timeMenu"
                  v-model="time"
                  format="24hr"
                  color="orange"
                  @click:minute="$refs.timeMenu.save(time)"
                ></v-time-picker>
              </v-menu>
              <v-menu
                v-model="timeDayMenu"
                :close-on-content-click="false"
                :nudge-right="-60"
                transition="scale-transition"
                offset-y
                color="orange"
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="timeDay"
                    :label="'Додати дату'"
                    readonly
                    prepend-icon="mdi-calendar"
                    color="orange"
                    v-bind="attrs"
                    v-on="on"
                    class="ml-5"
                  />
                </template>
                <v-date-picker
                  :locale="currentLanguage.code"
                  no-title
                  v-model="timeDay"
                  @input="timeDayMenu = false"
                  min="2021"
                ></v-date-picker>
              </v-menu>
            </v-col>
            <!--Menu-->
            <v-col v-if="type === 'card'" :cols="$vuetify.breakpoint.width <= 695 ? '12' : '3'" :class="$vuetify.breakpoint.width <= 750 ? 'mr-3' : ''">
              <v-autocomplete
                :items="inputTaskOptions"
                item-text="name"
                item-value="id"
                hide-details
                outlined
                dense
                v-model="inputTask"
                item-color="orange"
                :label="'Таски'"
                color="orange"
                :disabled="type === 'card'"
              ></v-autocomplete>
          </v-col>
            <v-col :cols="type === 'card' && $vuetify.breakpoint.width <= 695 ? '12' : '3' ? 3 : 5" :class="$vuetify.breakpoint.width <= 695 ? 'd-flex align-center my-2' : 'd-flex align-center pr-5'">
              <v-autocomplete
                :items="activityList"
                item-text="name"
                item-value="id"
                hide-details
                outlined
                dense
                v-model="selectedActivity"
                item-color="orange"
                :label=" $t('btn.ActivityProjectDialog') "
                color="orange"
              ></v-autocomplete
              >
            </v-col>
            <v-col class="d-flex align-center justify-content-end">
              <v-btn
                @click="addTime"
                small
                :loading="isAdding"
                class="btn-orange-bg"
                elevation="0"
                style="text-transform: none; font-size: 14px;"
              >
                <v-icon class="text-white" size="20" color="white">mdi-plus</v-icon>
                {{ $t("btn.add") }}
              </v-btn>
            </v-col>
            <v-col cols="12" style="flex-grow: 1">
              <v-text-field
                v-model="description"
                :placeholder="$t('form.taskDescriptionDialog.PostComment')"
                dense
                class="activity-message-field"
                outlined
                style="font-size: 14px"
                hide-details
                color="orange"
                :maxLength="225"
                height="45px"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-title>
        
        <v-divider />
<!--         <v-row class="d-flex my-0 mx-5 justify-space-around align-center">
          <span>Користувач</span>
          <span>Активність</span>
          <span>Завдання</span>
          <span>Дата</span>
          <span>Час</span>
        </v-row>
        <v-divider /> -->

        <v-card-text class="pt-3">
          <v-row v-if="timeLines && timeLines.length">
            <v-col
              cols="12"
              class="pt-0 pb-0"
              v-for="(item, index) in timeLines"
              :key="index"
              :ref="index === 0 ? 'firstActivity' : null"
            >
              <v-row class="my-2 mx-0 align-center flex-wrap" style="justify-content: space-evenly; gap: 10px;">

                <v-col cols="auto" class="d-flex align-center pb-0 px-0 pt-0">
                  <t-avatar :user="item.user" />
                  <span class="px-2 font-weight-bold font-14">{{ item.user.username }}</span>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.width <= 681 ? 'auto' : '2'" class="pa-0">
                  <div class="font-weight-bold d-flex align-center"
                       :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" color="orange" size="20" class="mr-1">mdi-ticket</v-icon>
                      </template>
                      <span class="font-weight-medium">{{ $t('form.taskDescriptionDialog.time_activity').slice(0, -1) }}</span>
                    </v-tooltip>
                    {{ item?.activity ? item?.activity?.name :  $t('form.taskDescriptionDialog.time_other')  }}
                  </div>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.width <= 681 ? 'auto' : '2'" class="pa-0">
                  <div class="font-weight-bold d-flex align-center"
                       :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" color="green" size="20" class="mr-1">mdi-text-box-outline</v-icon>
                      </template>
                      <span class="font-weight-medium">{{ $t('table.task') }}</span>
                    </v-tooltip>
                    {{ item?.card?.name ? item?.card?.name :  $t('form.taskDescriptionDialog.time_other')  }}
                  </div>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.width <= 681 ? 'auto' : '2'" class="pa-0">
                  <div class="font-weight-bold"
                       :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
                  >

                  <v-row class="ma-0 flex-nowrap pa-0 d-flex align-center">

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs"  color="blue" class="mr-2" size="20">mdi-clipboard-text-clock-outline</v-icon>
                      </template>
                      <span class="font-weight-medium">{{ $t('form.taskDescriptionDialog.date') }}</span>
                    </v-tooltip>

                       {{ item?.date ? item?.date :  ''  }}
                      <v-col v-if="isDateDifferent(item.date, item.created_at)" class="ml-2 pa-0 justify-end d-flex mr-6" cols="auto">
                        <v-menu v-model="menu[item.id]" offset-y left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="orange" icon small v-bind="attrs" v-on="on">
                              <v-icon size="20">mdi-alert</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-text>
                              {{ $t("form.taskDescriptionDialog.date_created") }} : {{ formattedDate(item.created_at) }}
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-col>
                    </v-row>

                  </div>
                </v-col>

                <v-col v-if="item.minutes" :cols="$vuetify.breakpoint.width <= 681 ? 'auto' : '2'" class="pa-0 d-flex justify-end">
                  <div
                      class="font-weight-bold d-flex align-center"
                      :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" color="orange" class="mr-1" size="20">mdi-clock-time-eight-outline</v-icon>
                      </template>
                      <span class="font-weight-medium">{{ $t('form.taskDescriptionDialog.time').slice(0, -1) }}</span>
                    </v-tooltip>
                    {{ getTime(item.minutes) }}
                    <v-icon @click="removeTime(item)" :class="{'is-deleting': isDeleting}" color="red" small class="mr-1">mdi-delete</v-icon>
                  </div>
                </v-col>

              </v-row>
              <v-col v-if="item.description" cols="12" class="pr-0 pl-6 pt-0">
                <div class="activityComment">{{ item.description }}</div>
              </v-col>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-content-end">
              <div class="font-weight-bold">
                {{ $t("form.total") }} {{ getTotalTime() }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        
        <v-dialog v-model="errorDialog" max-width="320" class="d-flex">
          <v-card class="error-card">
            <v-card-title class="red--text d-flex align-center" style="font-size: 12px;">
              <v-icon color="red" class="mr-5">mdi-alert-circle</v-icon>
              {{ errorMessage }}
            </v-card-title>
          </v-card>
        </v-dialog>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import cardsService from "@/services/request/cards/cardsService";
import dialogMixin from "@/mixins/dialogMixin";
import { required } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import {validationForTimePicker} from "@/mixins/helpers";
import projectsService from "@/services/request/projects/projectsService";
import userMixin from "@/mixins/user";
import {mapGetters} from "vuex";
import TimePicker from "@/components/UI/timePicker.vue";

let totalMinutes = null;

export default {
  components: {TimePicker},
  mixins: [notifications, dialogMixin, validationMixin, userMixin],
  data() {
    return {
      timeLines: [],
      timeMenu: false,
      timeDayMenu: false,
      date: null,
      time: "01:00",
      timeDay: new Date().toISOString().substr(0, 10),
      description: "",
      isDeleting: false,
      selectedTask: null,
      selectedActivity: null,
      activityList: [],
      inputTaskOptions: [],
      menu: {},
      isAdding: false,
      errorDialog: false,
      errorMessage: "",
    };
  },
  validations: {
    time: { required },
  },
  props: {
    inputTask: {
      type: Object,
      required: false
    },
    items: { 
      type: Array 
    },
    type: {
      required: true
    },
    board: {
      type: Object,
    },
    users: {
      required: true,
      default: [],
    },
  },
  async mounted() {
    if(this.type == 'card') {
      await this.fetchTimeLineCard();
      this.inputTaskOptions.push(this.inputTask);
    }
    await this.getActivity();
  },
  methods: {
    formattedDate(dateTime) {
      const date = new Date(dateTime);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day}, ${hours}:${minutes}`;
    },
    isDateDifferent(selectedDate, createdAt) {
      const selected = new Date(selectedDate);
      const created = new Date(createdAt);
      return (
        selected.getFullYear() !== created.getFullYear() ||
        selected.getMonth() !== created.getMonth() ||
        selected.getDate() !== created.getDate()
      );
    },
    async getActivity() {
      try {
        this.activityList = await projectsService.getActivityProject(this.board.project_id);
        console.log("this.activityList = ", this.activityList);
      } catch (e) {
        console.log(e);
      } finally {
      }
    },
    validationForTimePicker,
    onCancel() {
      this.description = "";
      this.selectedActivity = "";
      this.time = "01:00";
      localStorage.setItem("time_created_period", JSON.stringify(""));
      this.$emit("close");
    },
    async fetchTimeLineCard() {
      this.loading = true;
      try {
        this.timeLines = await cardsService.getTimeFromCard(
            this.board.project_id,
            this.board.id,
            this.inputTask.list_id,
            this.inputTask.id
        );
        this.timeLines.map((item) => (item.loading = false));
        this.timeLines.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        console.log(this.timeLines);
        this.loading = false;
      }
    },
    async fetchTimeLineUser(filterProps) {
      this.loading = true;
      try {
        const params = {};
        params.user_id = this.getUser.id;

        if(filterProps.date_created_begin && filterProps.date_created_end) {
          params.date_from = filterProps.date_created_begin;
          params.date_to = filterProps.date_created_end;
        } else if(filterProps.time_created) {
          params.date_from = filterProps.time_created;
          params.date_to = filterProps.time_created;
        }
        this.timeLines = await cardsService.getTimeFromUser(
            this.board.project_id,
            params
        );

        this.timeLines?.map((item) => (item.loading = false));
        this.timeLines?.sort(
            (a, b) => new Date(b.created_at) - new Date(a.created_at)
        );
      } catch (e) {
        this.setErrorNotification(e.message);
      } finally {
        this.loading = false;
      }
    },
    async addTime() {
      let RealDate = new Date().toISOString().substr(0, 10);
      if (new Date(this.timeDay) > new Date(RealDate)) {
        this.errorMessage = this.$t("form.timeError");
        this.errorDialog = true;
        setTimeout(() => {
          this.errorDialog = false;
        }, 4000);
      }
      if (this.isAdding) return;
      this.isAdding = true;
      await new Promise(resolve => setTimeout(resolve, 200));
      this.$v.$touch();
      if (!this.$v.$invalid) {
        try {
          if (this.time) {
            const [hours, minutes] = this.time.split(":").map(Number);
            totalMinutes = hours * 60 + minutes;
          }
          const formData = new FormData();
          formData.append("minutes", totalMinutes ? totalMinutes : null);
          formData.append("date", this.timeDay ? this.timeDay : null);
          if(this.inputTask) {
            formData.append("card_id", this.inputTask.id);
          } else if(this.selectedTask ) {
            formData.append("card_id", this.selectedTask);
          }
          if(this.selectedActivity) {
            formData.append("activity_id", this.selectedActivity);
          }

          if (this.description) {
            formData.append("description", this.description);
          }
          const response = await cardsService.addUsedTime(
              this.board.project_id,
              formData
          );
          if (response) {
            this.timeLines.push(response);
            this.timeLines.sort(
                (a, b) => new Date(b.created_at) - new Date(a.created_at)
            );
            this.description = "";
            this.selectedActivity = "";
            this.time = "01:00";
            this.$v.$reset();
            this.setSuccessNotification(this.$t("profile.successfully_created"));
          }
        } catch (e) {
          this.setErrorNotification(e.error.description);
        } finally {
          this.$emit("setCardLoading", false);
          this.isAdding = false;
        }
      }
    },
    async removeTime(timeLine) {
      this.isDeleting = true;
      const searchItemIndex = this.timeLines.findIndex(
          (item) => item.id === timeLine.id
      );
      this.$set(this.timeLines[searchItemIndex], "loading", true);
      try {
        await cardsService.deleteTime(
            this.board.project_id,
            timeLine.id
        );
        this.$set(this.timeLines[searchItemIndex], "loading", false);
        this.timeLines.splice(searchItemIndex, 1);
      } catch (e) {
        this.setErrorNotification(e.message);
      }
      finally { this.isDeleting = false; }
    },
    onTimeBlur() {
      const timePattern = /^([0-1]?[0-9]|2[0-3]):([0-5][0-9])$/;
      const [hours, minutes] = this.time.split(":").map(Number);

      if (!timePattern.test(this.time) || (hours > 23 || minutes > 59)) {
        this.time = "01:00";
      }
    },
    getUserData(item) {
      return this.users.find((user) => user.user.id === item.user.id).user;
    },
    getTotalTime() {
      let totalMinutes = 0;
      this.timeLines.forEach(item => {
        const minutes = parseInt(item.minutes, 10);
        if (!isNaN(minutes)) {
          totalMinutes += minutes;
        }
      });
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours} год ${minutes} хв`;
    },

  },
  computed: {
    getTime() {
      return (time) => {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        return `${hours}г ${minutes}хв`;
      };
    },
    timeErrors() {
      const errors = [];
      if (!this.$v.time.$dirty) {
        return errors;
      }
      !this.$v.time.required &&
      errors.push(this.$t("form.errors.timeRequired"));
      return errors;
    },
    timeDayErrors() {
      const errors = [];
      if (!this.$v.timeDay.$dirty) {
        return errors;
      }
      !this.$v.timeDay.required &&
      errors.push(this.$t("form.errors.timeRequired"));
      return errors;
    },
    ...mapGetters(["currentLanguage"]),
  },
};
</script>

<style scoped lang="scss">
.error-card {
  border: 2px solid #ff5252;
  box-shadow: 0px 4px 10px rgba(255, 82, 82, 0.2);
}
.attachment-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.attachment-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.attachment-thumbnail {
  width: 100px;
  height: 100px;
  cursor: pointer;
  border-radius: 4px;
  margin-right: 10px;
  object-fit: cover;
  flex-grow: 0 !important;
  .theme--light & {
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  .theme--dark & {
    border: thin solid rgba(255, 255, 255, 0.12);
  }
}

.attachment-date {
  margin-right: auto;
}

.is-deleting {
  pointer-events: none;
}
</style>

<style lang="scss">
@import "src/components/boards/taskDescriptionDialog.scss";
</style>
