<template>
  <div>
    <v-dialog
        v-model="visibility"
        max-width="800"
        content-class="cross__dialog"
        :transition="$dialogTransition"
        @click:outside="onCancel()"
        scrollable
    >
      <v-card>
        <v-slide-x-transition>
          <div class="cross__inner">
            <v-btn fab small elevation="0" @click="onCancel()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </div>
        </v-slide-x-transition>
        <v-card-title>
          <span class="d-flex align-center">
            <v-avatar v-if="TimeUser.project_time.project_media" size="35">
              <v-img :src="TimeUser.project_time.project_media" alt="project image" />
            </v-avatar>
            <v-avatar v-else size="35" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(TimeUser.project_time.name) }}</v-avatar>
            <v-tooltip right>
              <template #activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :color="TimeUser.project_time.flag"
                  small
                  class="ml-6 mt-n7 custom-btn-project btn_flag"
                  style="position: absolute;"
                >
                  <v-icon size="10">mdi-flag-variant</v-icon>
                </v-btn>
              </template>
                <span>{{ TimeUser.project_time.comment || "--" }}</span>
            </v-tooltip>
            <div class="mx-2" style="overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">{{ TimeUser.project_time.name }}</div>
          </span>

          <div class="d-flex justify-center ml-auto" style="width: auto;">
            <span class="disabled-date">
              {{ TimeUser?.date || TimeUser?.date_from + ' ~ ' + TimeUser?.date_to }}
            </span>
          </div>

        </v-card-title>
        <v-divider />
        <v-card-text class="pt-3">
          <v-row v-if="GetTimeUser && GetTimeUser.length">
            <v-col
              cols="12"
              class="pt-0 pb-0"
              v-for="(item, index) in GetTimeUser"
              :key="index"
              :ref="index === 0 ? 'firstActivity' : null"
            >
              <v-row class="my-2 mx-0 align-center flex-wrap" style="justify-content: space-evenly; gap: 10px;">

                <v-col cols="auto" class="d-flex align-center pb-0 px-0 pt-0">
                  <t-avatar :user="item.user" />
                  <span class="px-2 font-weight-bold font-14">{{ item.user.username }}</span>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.width <= 681 ? 'auto' : '2'" class="pa-0">
                  <div class="font-weight-bold d-flex align-center"
                       :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" color="orange" size="20" class="mr-1">mdi-ticket</v-icon>
                      </template>
                      <span class="font-weight-medium">{{ $t('form.taskDescriptionDialog.time_activity').slice(0, -1) }}</span>
                    </v-tooltip>
                    <span class="field-truncate">
                      {{ item?.activity ? item?.activity?.name :  $t('form.taskDescriptionDialog.time_other')  }}
                    </span>
                  </div>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.width <= 681 ? 'auto' : '2'" class="pa-0">
                  <div class="font-weight-bold d-flex align-center"
                       :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" color="green" size="20" class="mr-1">mdi-text-box-outline</v-icon>
                      </template>
                      <span class="font-weight-medium">{{ $t('table.task') }}</span>
                    </v-tooltip>
                    <span class="field-truncate">
                      {{ item?.card?.name ? item?.card?.name :  $t('form.taskDescriptionDialog.time_other')  }}
                    </span>
                  </div>
                </v-col>

                <v-col :cols="$vuetify.breakpoint.width <= 681 ? 'auto' : '2'" class="pa-0">
                  <div class="font-weight-bold"
                       :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
                  >

                  <v-row class="ma-0 flex-nowrap pa-0 d-flex align-center">

                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs"  color="blue" class="mr-2" size="20">mdi-clipboard-text-clock-outline</v-icon>
                      </template>
                      <span class="font-weight-medium">{{ $t('form.taskDescriptionDialog.date') }}</span>
                    </v-tooltip>

                       {{ item?.date ? item?.date :  ''  }}
                      <v-col v-if="isDateDifferent(item.date, item.created_at)" class="ml-2 pa-0 justify-end d-flex mr-6" cols="auto">
                        <v-menu v-model="menu[item.id]" offset-y left>
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn color="orange" icon small v-bind="attrs" v-on="on">
                              <v-icon size="20">mdi-alert</v-icon>
                            </v-btn>
                          </template>
                          <v-card>
                            <v-card-text>
                              {{ $t("form.taskDescriptionDialog.date_created") }} : {{ formattedDate(item.created_at) }}
                            </v-card-text>
                          </v-card>
                        </v-menu>
                      </v-col>
                    </v-row>

                  </div>
                </v-col>

                <v-col v-if="item.minutes" :cols="$vuetify.breakpoint.width <= 681 ? 'auto' : '2'" class="pa-0 d-flex justify-end">
                  <div
                      class="font-weight-bold d-flex align-center"
                      :style="$vuetify.theme.dark ? 'color: #FFF' : 'color :#000'"
                  >
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" color="orange" class="mr-1" size="20">mdi-clock-time-eight-outline</v-icon>
                      </template>
                      <span class="font-weight-medium">{{ $t('form.taskDescriptionDialog.time').slice(0, -1) }}</span>
                    </v-tooltip>
                    {{ getTime(item.minutes) }}
                  </div>
                </v-col>

              </v-row>
              <v-col v-if="item.description" cols="12" class="pr-0 pl-6 pt-0">
                <div class="activityComment">{{ item.description }}</div>
              </v-col>
            </v-col>
            <v-col cols="12" class="d-flex align-center justify-content-end">
              <div class="font-weight-bold">
                {{ $t("form.total") }} {{ getTotalTime() }}
              </div>
            </v-col>
          </v-row>
        </v-card-text>
        
        <v-dialog v-model="errorDialog" max-width="320" class="d-flex">
          <v-card class="error-card">
            <v-card-title class="red--text d-flex align-center" style="font-size: 12px;">
              <v-icon color="red" class="mr-5">mdi-alert-circle</v-icon>
              {{ errorMessage }}
            </v-card-title>
          </v-card>
        </v-dialog>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import notifications from "@/mixins/notifications";
import cardsService from "@/services/request/cards/cardsService";
import dialogMixin from "@/mixins/dialogMixin";
import userMixin from "@/mixins/user";
import TimePicker from "@/components/UI/timePicker.vue";

export default {
  components: {TimePicker},
  mixins: [notifications, dialogMixin, userMixin],
  data() {
    return {
      menu: {},
      errorDialog: false,
      errorMessage: "",
      GetTimeUser: [],
    };
  },
  validations: {
  },
  props: {
    TimeUser: {
      required: false
    }
  },
  async mounted() {
    await this.Mounted();
  },
  methods: {
    async Mounted() {
      let params = {};
      params.date_to = this.TimeUser?.date || this.TimeUser.date_to;
      params.date_from = this.TimeUser?.date || this.TimeUser.date_from;
      params.user_id = this.TimeUser.user.user_id;
      this.GetTimeUser = await cardsService.getTimeFromUser(this.TimeUser.project_time.id, params);
    },
    formattedDate(dateTime) {
      const date = new Date(dateTime);
      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();
      const hours = String(date.getHours()).padStart(2, "0");
      const minutes = String(date.getMinutes()).padStart(2, "0");
      return `${year}-${month}-${day}, ${hours}:${minutes}`;
    },
    isDateDifferent(selectedDate, createdAt) {
      const selected = new Date(selectedDate);
      const created = new Date(createdAt);
      return (
        selected.getFullYear() !== created.getFullYear() ||
        selected.getMonth() !== created.getMonth() ||
        selected.getDate() !== created.getDate()
      );
    },
    getLetters(item) {
      let letters = "";
      if (item) {
        letters = item?.trim().charAt(0).toUpperCase();
      }
      return letters;
    },
    onCancel() {
      this.$emit("close");
    },
    getTotalTime() {
      let totalMinutes = 0;
      this.GetTimeUser.forEach(item => {
        const minutes = parseInt(item.minutes, 10);
        if (!isNaN(minutes)) {
          totalMinutes += minutes;
        }
      });
      const hours = Math.floor(totalMinutes / 60);
      const minutes = totalMinutes % 60;
      return `${hours} год ${minutes} хв`;
    },

  },
  computed: {
    getTime() {
      return (time) => {
        const hours = Math.floor(time / 60);
        const minutes = time % 60;
        return `${hours}г ${minutes}хв`;
      };
    },
  },
};
</script>

<style scoped lang="scss">
.field-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
}

.disabled-date {
  .theme--light & {
    color: #ffffff !important;
    background-color: #00000054;
  }
  .theme--dark & {
    color: #ffffff !important;
    background-color: #00000046;
  }
  padding: 5px 10px;
  border-radius: 5px;
  pointer-events: none;
  transform: scale(0.8);
}
.error-card {
  border: 2px solid #ff5252;
  box-shadow: 0px 4px 10px rgba(255, 82, 82, 0.2);
}
</style>

<style lang="scss">
@import "src/components/boards/taskDescriptionDialog.scss";
</style>
