<template>
  <v-dialog 
    v-model="visibility"
    max-width="550"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    persistent
  >
    <v-card style="min-height: 435px;" class="pb-1">
      <v-fade-transition>
        <v-progress-linear 
          v-if="loading"
          absolute
          top
          indeterminate
          color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="onCancel()">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>
      <div class="mx-3 mt-3" style="position: absolute;">
          <t-avatar :user="DeviceData.owner" />
          <!-- <span class="pl-2">{{ DeviceData.owner.username }}</span> -->
        </div>
      <v-card-title class="justify-center pt-3 pa-0">{{ type == 'create' ? $t('form.DeviceDialog.createDevice'):$t('form.DeviceDialog.repeatDevice') }}</v-card-title>
      <v-divider class="mt-3"/>
      <v-row class="mx-5 mt-0 mb-0 pt-5 px-0 align-top justify-space-around" style="gap: 0px;">
        <v-col :cols="$vuetify.breakpoint.width < 505 ? '12':'6'" class="ma-0 pa-0">
          <t-img 
            :class="{ 'disabled': 
              (DeviceData.user_id != getUserData.id)
              && (ProjectDublicate.role == 3) && type != 'create'
              || DeviceData.status == 'In_Transfer' || DeviceData.status == 'Blocked'
            }"
            :customWidth="180"
            @success="changeImage"
            @reset="resetImage"
            :visibleLoadImg="project ? type === 'header_select' : true"
            @editChangeImage="editChangeImage"
          />
        </v-col>
        <v-col :cols="$vuetify.breakpoint.width < 505 ? '12':'6'" :class="$vuetify.breakpoint.width < 505 ? 'mt-4 pa-0 pr-0':'ma-0 pl-3 py-0 pr-0'">
          <v-text-field
            v-model="DeviceData.name"
            hide-details
            dense
            :disabled="type == 'header_select' || project && ((DeviceData.user_id != getUserData.id)
            && (ProjectDublicate.role == 3) && type != 'create'
            || DeviceData.status == 'In_Transfer' || DeviceData.status == 'Blocked')"
            :label="$t('form.DeviceDialog.nameDevice')"
            outlined
            color="orange"
          />
          <v-text-field
            v-model="DeviceData.inventory_id"
            :disabled="type == 'header_select' || project && ((DeviceData.user_id != getUserData.id)
            && (ProjectDublicate.role == 3) && type != 'create'
            || DeviceData.status == 'In_Transfer' || DeviceData.status == 'Blocked')"
            class="mt-4"
            hide-details
            dense
            :label="$t('form.DeviceDialog.modelDevice')"
            outlined
            color="orange"
          />
          <v-select
            outlined
            :items="usersList"
            item-text="username"
            return-object
            hide-details
            color="orange"
            item-color="orange"
            :placeholder="$t('form.DeviceDialog.OwnerDevice')"
            class="mt-4"
            v-model="DeviceData.user"
            @change="selectUser"
            dense
            :item-disabled="isItemUserDisabled"
            :disabled="type == 'header_select' || project && (DeviceData.user_id != getUserData.id && type != 'create' || DeviceData.status == 'In_Transfer' && type != 'create' || disableUserAndProj)"
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>
                    <t-avatar :user="item" />
                    <span class="pl-2">{{ item.username }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center">
                <t-avatar :user="item" />
                <span class="pl-2">{{ item?.username }}</span>
              </div>
            </template>
            <template v-slot:append>
              <v-icon v-if="DeviceData.status == 'Issued' && !UserSelect" color="green">mdi-account</v-icon>
              <v-icon v-if="DeviceData.status == 'In_Transfer' || UserSelect" color="orange">mdi-clock-outline</v-icon>
            </template>
          </v-select>
          <!-- <div class="d-flex">
            <v-btn v-if="DeviceData.user_to_transfer_id == getUserData.id" class="mt-3 ml-auto d-flex" outlined icon small color="green" @click="SuccsessDeviceUser">
              <v-icon size="20">mdi-check</v-icon>
            </v-btn>
            <v-btn :class="DeviceData.user_id == getUserData.id && DeviceData.status == 'In_Transfer' ? 'ml-auto':''" v-if="DeviceData.user_to_transfer_id == getUserData.id || (DeviceData.user_id == getUserData.id && DeviceData.status == 'In_Transfer')" class="mt-3 ml-2 d-flex" outlined icon small color="red" @click="CancelDeviceUser">
              <v-icon size="20">mdi-close</v-icon>
            </v-btn>
          </div> -->
          <div class="mt-n2">
            <v-select
              outlined
              :items="CommonProjects"
              item-text="name"
              return-object
              hide-details
              color="orange"
              item-color="orange"
              :placeholder="$t('form.DeviceDialog.deviceProjectName')"
              class="mt-4"
              v-model="SelectProject"
              @change="selectProjectMethod"
              dense
              :item-disabled="isItemProjectDisabled"
              :disabled="type == 'header_select' || project && (DeviceData.user_id != getUserData.id && type != 'create' || DeviceData.status == 'In_Transfer' || disableUserAndProj)"
            >
              <template v-slot:item="{ item, attrs, on }">
                <v-list-item v-on="on" v-bind="attrs" :disabled="item.disabled" :class="{ 'custom-disabled': item.disabled }">
                  <v-list-item-content>

                    <v-list-item-title v-if="item.myProj">
                      <span style="font-size: 15px;">{{ item.name }}</span>
                    </v-list-item-title>

                    <v-list-item-title v-if="!item.disabled && !item.myProj">
                      <v-avatar v-if="item.project_media" size="30">
                        <v-img :src="item.project_media" alt="project image" />
                      </v-avatar>
                      <v-avatar v-else size="30" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(item.name) }}</v-avatar>
                      
                      <v-tooltip right>
                        <template #activator="{ on, attrs }">
                          <v-btn
                            v-bind="attrs"
                            v-on="on"
                            :color="item.flag"
                            small
                            class="ml-n2 mt-0 custom-btn-project btn_flag"
                            style="position: absolute;"
                          >
                            <v-icon size="10" color="white">mdi-flag-variant</v-icon>
                          </v-btn>
                        </template>
                          <span>{{ item.comment || "--" }}</span>
                      </v-tooltip>
                      
                      <span class="pl-4">{{ item.name }}</span>
                    </v-list-item-title>

                    <v-list-item-title v-else-if="!item.myProj">
                      <span style="font-size: 15px;">{{ item.name }}</span>
                    </v-list-item-title>

                    <div v-if="!isLoader_page && item === lastProject"
                      class="observer"
                      style="height: 1px;"
                      v-observer="{ nextPage: MoreProjects }">
                    </div>

                  </v-list-item-content>

                  <v-list-item-action>
                    <v-spacer />
                    <v-list-item-subtitle class="justify-end d-flex mr-5">
                    <div v-if="item.role === 1">
                      <v-icon>mdi-account-cowboy-hat</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.owner") }}
                      </span>
                    </div>
                    <div v-if="item.role === 2">
                      <v-icon>mdi-account-star</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.project_manager") }}
                      </span>
                    </div>
                    <div v-else-if="item.role === 3">
                      <v-icon>mdi-account</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.staff") }}
                      </span>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-action>
              </v-list-item>

            </template>
            
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center">
                <v-avatar v-if="item.project_media" size="30">
                      <v-img :src="item.project_media" alt="project image" />
                    </v-avatar>
                    <v-avatar v-else size="30" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(item.name) }}</v-avatar>
                    <v-tooltip right>
                      <template #activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          :color="item.flag"
                          small
                          class="ml-5 mb-4 custom-btn-project btn_flag"
                          style="position: absolute;"
                        >
                          <v-icon size="10" color="white">mdi-flag-variant</v-icon>
                        </v-btn>
                      </template>
                        <span>{{ item.comment || "--" }}</span>
                    </v-tooltip>
                  <span class="pl-2">{{ item.name.length > 12 ? item.name.slice(0, 12) + '...' : item.name }}</span>
              </div>
            </template>
              <!-- <template v-slot:append>
                <v-icon v-if="DeviceData.status == 'Issued' && !UserSelect" color="green">mdi-check</v-icon>
                <v-icon v-if="DeviceData.status == 'In_Transfer' || UserSelect" color="orange">mdi-clock-outline</v-icon>
              </template> -->
              
            </v-select>
              
          </div>

          <div class="d-flex justify-end">
            <v-btn v-if="DeviceData.user_to_transfer_id == getUserData.id && (DeviceData.project_to_transfer_id == ProjectDublicate.id || DeviceData.project_to_transfer_id == null)" class="mt-3" outlined icon small color="green" @click="SuccsessDeviceUser">
              <v-icon size="20">mdi-check</v-icon>
            </v-btn>
            <v-btn v-if="DeviceData.user_to_transfer_id == getUserData.id || (DeviceData.user_id == getUserData.id && DeviceData.status == 'In_Transfer')" class="mt-3 ml-2" outlined icon small color="red" @click="CancelDeviceUser">
              <v-icon size="20">mdi-close</v-icon>
            </v-btn>
          </div>

        </v-col>
        
        <v-col cols="12" class="mt-3 pa-0">
          <v-textarea
            v-model="DeviceData.comment"
            :disabled="type == 'header_select' || project && ((DeviceData.user_id != getUserData.id)
            && (ProjectDublicate.role == 3) && type != 'create'
            || DeviceData.status == 'In_Transfer' || DeviceData.status == 'Blocked')"
            hide-details
            rows="3"
            :maxLength="240"
            color="orange"
            outlined
            :label="$t('form.DeviceDialog.commentDevice')"
          >
          </v-textarea>
        </v-col>
      </v-row>

      
      <div class="ma-0 pa-0 d-flex align-center">
        
        <div class="ml-3" v-if="type != 'create'">
          <v-tooltip top>
            <template v-slot:activator="{on, attrs}">
              <v-btn v-on="on" v-bind="attrs" color="green" icon v-if="DeviceData.status != 'deactivate' && DeviceData.status != 'Deactivated'"
                :disabled="type == 'header_select' || project && (DeviceData.status == 'block' || ProjectDublicate.role == 3 || (DeviceData.user_to_transfer_id != null))"
                @click="BlockDevice"
                >
                <v-icon size="20">mdi-lock-open</v-icon>
              </v-btn>
              <v-btn v-on="on" v-bind="attrs" @click="BlockDevice" icon color="red" v-else :disabled="type == 'header_select' || project && (ProjectDublicate.role == 3 || DeviceData.status == 'block' || DeviceData.user_to_transfer_id == getUserData.id)">
                <v-icon size="20">mdi-lock</v-icon>
              </v-btn>
            </template>
            <span v-if="DeviceData.status == 'deactivate' || DeviceData.status == 'Deactivated'">{{ $t("form.DeviceDialog.DeviceBlock") }}</span>
            <span v-else>{{ $t("form.DeviceDialog.DeviceUnBlock") }}</span>

          </v-tooltip>
        </div>

        <div class="d-flex ml-auto">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
                <td v-bind="attrs" v-on="on" v-if="type != 'create'">
                  <v-switch
                    color="orange"
                    hide-details="auto"
                    v-model="isBlockDevice"
                    @change="handleBlockDeviceToggle"
                    inset
                    dense
                    v-if="ProjectDublicate.role < 3 || DeviceData.user_id == getUserData.id"
                    :disabled="type == 'header_select' || project && (DeviceData.user_to_transfer_id == getUserData.id || DeviceData.status == 'deactivate' || DeviceData.status == 'Deactivated' || DeviceData.status == 'activate' || (DeviceData.user_id == getUserData.id && DeviceData.status == 'In_Transfer'))"
                  ></v-switch>
                </td>
              </template>
              <span v-if="isBlockDevice">{{$t("form.DeviceDialog.unblockDevice")}}</span>
              <span v-if="!isBlockDevice">{{ $t("form.DeviceDialog.blockDevice") }}</span>
          </v-tooltip>
        </div>
        <v-btn :disabled="!change || DeviceData.name == ''" :loading="loading" v-if="(DeviceData.user_id == getUserData.id || DeviceData.user_id == null || ProjectDublicate.role <= 3) && project && type != 'header_select'" color="orange" class="mr-5 mt-3 mb-2" @click="type == 'create' ? CreateDevice() : UpdateDevice()">
          {{ type == 'create' ? $t("btn.create"): $t("btn.update") }}
        </v-btn>
      </div>

    </v-card>

  </v-dialog>
</template>

<script>
import notifications from '@/mixins/notifications';
import dialogMixin from '@/mixins/dialogMixin';
import projectsService from '@/services/request/projects/projectsService';
import tImgMixin from '@/mixins/tImgMixin'; 
import EventBus from "@/events/EventBus";
import _ from "lodash";
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";

export default {
  mixins: [notifications, dialogMixin, tImgMixin],
  data: () => ({
    loading: false,
    DeviceData: {},
    inputDeviceData: {},
    copyDeviceData: {},
    change: false,
    usersList: [],
    showUsersMenu: false,
    UserSelect: null,
    UserUuid: null,
    CommonProjects: [],
    SelectProject: null,
    isBlockDevice: false,
    disableUserAndProj: false,
    isLoader_page: false,
    page: 1,
    loadOwner: true,
    lastProject: null,
    ProjectDublicate: '',
  }),
  props: {
    project: {
      require: false,
    },
    type: {
      require: true,
    },
    selectDevice: {
      require: false,
      default: Boolean
    },
  },
  components: {
    projectsService
  },
  async mounted() {
    this.onMounted();
    this.project === '' ? this.qwe() : this.ewq();
    await this.usersListData();
    await this.MoreProjects();
    
    console.log("TYPE :", this.type);

    //console.log('this.project :', this.project);
//
    //console.log("this.ProjectDublicate :", this.ProjectDublicate);
    //console.log("this.DeviceData.project :", this.DeviceData.project);
  },
  methods: {
    qwe() {
      console.log("1");
      this.ProjectDublicate = this.DeviceData.project;
    },
    ewq() {
      console.log("2");
      this.ProjectDublicate = this.project;
    },
    onMounted() {
      this.loading = true;
      if (this.selectDevice) {
        this.DeviceData = this.selectDevice;
        if(this.DeviceData.status == 'Issued'){
          this.DeviceData.status = 'Issued';
          this.isBlockDevice = false;
        }
        if(this.DeviceData.status == 'Blocked' || this.DeviceData.status == 'block'){
          this.isBlockDevice = true;
          this.disableUserAndProj = true;
        }
      } else {
        this.DeviceData = {
          name: '',
          device_media: null,
          inventory_id: '',
          comment: '',
          user_id: this.getUserData.id,
          user: this.getUserData,
        };
        this.disableUserAndProj = true;
      }
      this.initDependencies();
      setTimeout(() => {
        this.loading = false;
      }, 300);
    },
    initDependencies() {
      this.inputDeviceData = {...this.DeviceData};
      this.copyDeviceData = {...this.DeviceData};
      this.T_IMG_OBJECT = this.DeviceData;
      this.T_IMG_COPY_OBJECT = this.copyDeviceData;
      this.T_IMG_MEDIA_KEY = "device_media";
      if (this.DeviceData.device_media) {
        EventBus.$emit("is-selected-image", this.DeviceData.device_media);
      }
    },

    async CreateDevice() {
      const formData = new FormData();
      formData.append("name", this.DeviceData.name);
      formData.append("inventory_id", this.DeviceData.inventory_id == null ? '': this.DeviceData.inventory_id);
      formData.append("comment", this.DeviceData.comment == null ? '': this.DeviceData.comment );
      formData.append("device_media", this.DeviceData.device_media == null ? '' : this.DeviceData.device_media);
      //formData.append("user_id", this.getUserData.id);
      //formData.append("user", JSON.stringify(this.getUserData));
      const createDeviceMethod = await projectsService.createDevice(this.DeviceData.project_id ?? this.ProjectDublicate.id, formData);
      //
      //createDeviceMethod.push({
      //  ...createDeviceMethod,
      //  user: this.getUserData,
      //});

      this.$emit("addDevice", createDeviceMethod);
      this.$emit("UPDATE_BTN");
      this.onCancel();
    },
    async SuccsessDeviceUser() {
      const formData = new FormData();
      formData.append("confirm", 1);
      const checksuccsess = await projectsService.SuccsessDeviceUser(this.DeviceData?.project_to_transfer_id ?? this.DeviceData?.project_id ?? this.ProjectDublicate?.id, this.DeviceData.id, formData);
      await this.$emit("UpdateDevice", checksuccsess);
      this.$emit("UPDATE_BTN");
      this.onCancel();
    },
    async CancelDeviceUser() {
      const formData = new FormData();
      formData.append("confirm", 0);
      const checkCancel = await projectsService.SuccsessDeviceUser(this.DeviceData.project_id ?? this.ProjectDublicate.id, this.DeviceData.id, formData);
      await this.$emit("UpdateDevice", checkCancel);
      this.$emit("UPDATE_BTN");
      if((this.DeviceData.project_id ?? this.ProjectDublicate.id) != this.DeviceData.project_id) {
        this.$emit("CancelMoveProject", checkCancel);
      }
      this.onCancel();
    },

    async UpdateDevice() {
      const formData = new FormData();
      
      if(this.copyDeviceData.name != this.DeviceData.name) {
        formData.append("name", this.DeviceData.name);
      }
      
      if(this.copyDeviceData.inventory_id != this.DeviceData.inventory_id) {
        formData.append("inventory_id", this.DeviceData.inventory_id == null ? '': this.DeviceData.inventory_id);
      }
      
      if(this.copyDeviceData.comment != this.DeviceData.comment) {
        formData.append("comment", this.DeviceData.comment == null ? '': this.DeviceData.comment);
      }
      
      if((this.DeviceData.project_id ?? this.ProjectDublicate.id) != this.SelectProject.id && this.SelectProject != null) {
        formData.append("project_to_transfer_id", this.SelectProject.id);
      }
      if(this.copyDeviceData.device_media != this.DeviceData.device_media) {
        formData.append("device_media", this.DeviceData.device_media == null ? '' : this.DeviceData.device_media);
      }
      if(this.DeviceData.status == 'block' || this.DeviceData.status == 'unblock') {
        if(this.DeviceData.status == 'unblock') {
          
          this.DeviceData.user_id = this.getUserData.id;
          this.DeviceData.user = this.getUserData;
          formData.append("status", this.DeviceData.status);
        }
        formData.append("status", this.DeviceData.status);
      }

      if(this.DeviceData.status == 'deactivate') {
        this.DeviceData.status = "deactivate";
        formData.append("status", this.DeviceData.status);
      } else if(this.DeviceData.status == 'activate') {
        this.DeviceData.status = "activate";
        formData.append("status", this.DeviceData.status);
      }

      if(this.getUserData.id != this.UserSelect && this.UserSelect != null) {
        formData.append("user_to_transfer_id", this.UserSelect.id);
        await this.$emit("transfer_user", this.UserSelect);
        let selectedUser = this.usersList.find(user => user.id === this.UserSelect.id);
      }
      const UpdateDeviceMethod = await projectsService.updateDevice(
        (this.DeviceData.project_id ?? this.ProjectDublicate.id),
        this.DeviceData.id,
        formData
      );
      if(this.DeviceData.device_media || this.UserSelect || this.SelectProject) {
        await this.$emit("UpdateDevice", UpdateDeviceMethod);
      }
      this.$emit("UPDATE_BTN");
      this.onCancel();
    },

    handleBlockDeviceToggle(value) {
      if (value) {
        this.DeviceData.status = 'block';
        this.disableUserAndProj = true;
        this.change = true;
      } 
      else {
        if(this.copyDeviceData.status == 'Issued') {
          this.DeviceData.status = 'Issued';
        } else {
          this.DeviceData.status = 'unblock';
        }
        this.disableUserAndProj = false;
        this.change = true;
      }
      this.DeviceData.user = this.copyDeviceData.user;
      this.SelectProject = (this.ProjectDublicate ?? this.DeviceData.project);
      this.UserSelect = null;

      console.log("END :", this.DeviceData.status);
    },

    async usersListData() {
      this.usersList = await projectsService.getUsers(this.DeviceData.project_id ?? this.ProjectDublicate.id).then((users) =>
        users.sort((a, b) => a.role - b.role).map(userItem => userItem.user)
      );
      this.UserUuid = this.usersList.filter(user => user.id == this.getUserData.id);
    },
    isItemUserDisabled(item) {
      const userId = this.UserUuid.length > 0 ? this.UserUuid[0].id : null;
      return item.id === userId;
    },
    isItemProjectDisabled(item) {

      return item.id === (this.DeviceData.project_id ?? this.ProjectDublicate.id);
    },
    selectUser(user) {
      if(user != null) {
        this.UserSelect = user;
        this.SelectProject = (this.ProjectDublicate ?? this.DeviceData.project);
      } else { this.UserSelect = ''; }
      
    },
    async projectsListData(NexPage) {
      const paramsMy = {
        per_page: 15,
        page: NexPage,
        owner: this.loadOwner,
      };
      
      let ProjGet = await projectsService.getList(paramsMy);
      
      if(ProjGet.length < 15) {
        this.loadOwner = false;
        this.page = 1;
      }
      else if(ProjGet.length < 15 && this.loadOwner == false) {
        this.isLoader_page = true;
      }
      this.CommonProjects = [...this.CommonProjects, ...ProjGet];
      
      //console.log("this.CommonProjects :", this.CommonProjects);
      this.CommonProjects.sort((a, b) => a.role - b.role);
      this.CommonProjects.unshift({
        id: "my-header",
        name: this.$t("home.created_by_me"),
        disabled: true,
        myProj: true,
      });
      const guestProjectIndex = this.CommonProjects.findIndex(item => item.role > 1 && item.id != (this.ProjectDublicate.id ?? this.DeviceData.project_id));
      if (guestProjectIndex !== -1) {
        this.CommonProjects.splice(guestProjectIndex, 0, {
          id: "guest-header",
          name: this.$t("home.guest_projects"),
          disabled: true,
          myProj: false,
        });
      }
      this.SelectProject = this.ProjectDublicate ?? this.DeviceData.project;
      this.CommonProjects.unshift({
        ...this.SelectProject,
      });
    },
    BlockDevice() {
      const currentStatus = this.DeviceData.status;
      if (currentStatus === 'deactivate' || currentStatus === 'Deactivated') {
        this.DeviceData.status = 'activate';
      } else {
        this.DeviceData.status = 'deactivate';
      }
      
      if(this.DeviceData.status != this.copyDeviceData.status) {
        this.disableUserAndProj = true;
      } else { this.disableUserAndProj = false; }

      console.log("this.DeviceData.status:", this.DeviceData.status);
    },
    async MoreProjects() {
      await this.projectsListData(this.page++);
    },
    selectProjectMethod(item) {
      this.SelectProject = item;
      this.UserSelect = null;
      this.DeviceData.user = this.copyDeviceData.user;
    },
    onCancel() {
      this.$emit("close");
    },
    getLetters(item) {
      let letters = "";
      if (item) {
        letters = item?.trim().charAt(0).toUpperCase();
      }
      return letters;
    },
  },
  computed: {
    ...mapGetters("auth", {
      getUserData: getterTypes.GET_USER_DATA,
    }),
  },
  watch: {
    CommonProjects(newVal) {
      this.lastProject = newVal[newVal.length - 2] || null;
    },
    DeviceData: {
      deep: true,
      handler(value, oldValue) {
        if(value.name != '') {
        if(value.inventory_id == "") {
          this.DeviceData.inventory_id = null;
        }
        if(value.comment == "") {
          this.DeviceData.comment = null;
        }
        this.change = !_.isEqual(this.copyDeviceData, this.DeviceData);  
        }
      }
    },
    'DeviceData.status': {
      immediate: true,
      handler(newStatus) {
        this.isBlockDevice = newStatus === 'block' || newStatus === 'Blocked';
      },
    },
    UserSelect: {
      immediate: false,
      handler(value, oldValue) {
        if(value != this.copyDeviceData.user) {
          this.change = true;
        } else { this.change = false; }
      }
    },
    SelectProject: {
      immediate: false,
      handler(value, oldValue) {
        if(value != (this.ProjectDublicate ?? this.DeviceData.project) && this.DeviceData.name != '') {
          this.change = true;
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">
.custom-disabled {
  pointer-events: none;
  color: inherit;
  opacity: 1;
  cursor: default;
}
.disabled {
  pointer-events: none;
  opacity: 0.8;
}
.menu-btn {
  min-width: 28px !important;
  width: 28px;
  height: 28px;
  border-radius: 4px;
  .theme--light & {
    background-color: #fff;
    border: thin solid rgba(0, 0, 0, 0.12);
  }
  .theme--dark & {
    background-color: #1e1e1e;
    border: thin solid rgba(255, 255, 255, 0.12);
  }
}
</style>