<template>
  <v-dialog
    v-model="visibility"
    max-width="900"
    content-class="cross__dialog"
    :transition="$dialogTransition"
    @click:outside="onCancel"
  >
    <v-card>
      <v-fade-transition>
        <v-progress-linear
          v-if="loading"
          absolute
          top
          indeterminate
          color="orange"
        ></v-progress-linear>
      </v-fade-transition>
      <v-slide-x-transition>
        <div class="cross__inner">
          <v-btn fab small elevation="0" @click="onCancel">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </div>
      </v-slide-x-transition>

      <v-row class="justify-space-between mx-2">
        <v-card-title class="justify-center">
          <v-select
            outlined
            dense
            chips
            return-object
            :items="usersList"
            item-text="username"
            item-value="id"
            hide-details
            color="orange"
            item-color="orange"
            :label="$t('table.participants')"
            v-model="selectedUsers"
            height="45"
            style="max-width: 220px; min-width: auto;"
          >
            <template v-slot:item="{ item, attrs, on }">
              <v-list-item v-on="on" v-bind="attrs">
                <v-list-item-content>
                  <v-list-item-title>
                    <t-avatar :user="item" />
                    <span class="pl-2">{{ item.username }}</span>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <template v-slot:selection="{ item }">
              <div class="d-flex align-center" style="max-width: 160px; min-width: 160px;">
                <t-avatar :user="item" />
                <span class="pl-2 username-truncate">{{ item?.username }}</span>
              </div>
            </template>
          </v-select>
        </v-card-title>

        <!-- <v-card-title class="justify-center">{{ $t("form.statisticBoard") }}</v-card-title> -->

        <!-- <v-card-title class="mr-7">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="orange" icon v-bind="attrs" v-on="on" :disabled="transformedData.length == 0" @click="ExportTable(transformedData, horizontalHeaders)">
                <v-icon>mdi-download</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("form.statisticExportLable") }}</span>
          </v-tooltip>
        </v-card-title> -->
      </v-row>

      <v-card-text>
        <v-data-table
          fixed-header
          mobile-breakpoint
          dense
          :items="transformedData"
          :headers="horizontalHeaders"
          :no-data-text="$t('table.noData')"
          hide-default-footer
          item-key="name"
          :items-per-page="transformedData.length"
          scrollable
          height="300"
        >
          <template v-slot:item="{ item }">
            <tr>
              <td>{{ item.name }}</td>
              <td>{{ item.total_tasks }}</td>
              <td>{{ item.total_bugs }}</td>
              <td>{{ item.total_p1 }}</td>
              <td>{{ item.total_p2 }}</td>
              <td>{{ item.total_p3 }}</td>
              <td>{{ item.total_p4 }}</td>
              <td>{{ item.total_p5 }}</td>
              <td>{{ item.total_story_points }}</td>
            </tr>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import dialogMixin from "@/mixins/dialogMixin";
import projectsService from "@/services/request/projects/projectsService";
import * as XLSX from "xlsx";
import notifications from "@/mixins/notifications";
import { mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";

export default {
  mixins: [dialogMixin, notifications],
  data: () => ({
    loading: false,
    TableStatistic: [],
    usersList: [],
    selectedUsers: null,
  }),
  props: {
    board: {
      require: false,
    },
  },
  async mounted() {
    await this.GetUsers();
    await this.GetStatistic();
  },
  methods: {
    async ExportTable(data) {
      try {
        const exportData = data.map(item => ({
          Name: item.name,
          Tasks: item.total_tasks,
          Bugs: item.total_bugs,
          P1: item.total_p1,
          P2: item.total_p2,
          P3: item.total_p3,
          P4: item.total_p4,
          P5: item.total_p5,
          "Story Points": item.total_story_points,
        }));

        const worksheet = XLSX.utils.json_to_sheet(exportData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "StatisticProjects");

        const csvData = XLSX.write(workbook, {
          bookType: "csv",
          type: "string",
          FS: ";",
        });

        const blob = new Blob(["\uFEFF" + csvData], { type: "text/csv;charset=utf-8;" });
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.setAttribute("download", "StatisticProjects.csv");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
        this.setSuccessNotification(this.$t("form.statisticSuccess"));
      } catch (e) {
        this.setErrorNotification(e.message);
      }
    },

    async GetStatistic() {
      this.loading = true;
      let params = {};
      params.user_id = this.selectedUsers.id;
      this.TableStatistic = await projectsService.ExportProjectsTable(params);
      this.loading = false;
    },

    async GetUsers() {
      this.usersList = await projectsService.getUsersManaged();
      this.selectedUsers = this.UserData;
    },

    onCancel() {
      this.$emit("close");
    },
  },
  computed: {
    horizontalHeaders() {
      return [
        { text: "", value: "name", sortable: false },
        { text: this.$t("table.task"), value: "total_tasks" },
        { text: this.$t("table.bug"), value: "total_bugs" },
        { text: "P1", value: "total_p1" },
        { text: "P2", value: "total_p2" },
        { text: "P3", value: "total_p3" },
        { text: "P4", value: "total_p4" },
        { text: "P5", value: "total_p5" },
        { text: this.$t("form.statisticStory"), value: "total_story_points" }
      ];
    },

    transformedData() {
      return this.TableStatistic.map(item => ({
        name: item.name,
        total_tasks: item.total_tasks,
        total_bugs: item.total_bugs,
        total_p1: item.total_p1,
        total_p2: item.total_p2,
        total_p3: item.total_p3,
        total_p4: item.total_p4,
        total_p5: item.total_p5,
        total_story_points: item.total_story_points,
      }));
    },

    ...mapGetters("auth", {
      UserData: getterTypes.GET_USER_DATA,
    }),
  },
  watch: {
    selectedUsers() {
      this.GetStatistic();
    }
  },
};
</script>

<style>
.username-truncate {
  display: block;
  max-width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.bold-header {
  font-size: 14px !important;
}
</style>
