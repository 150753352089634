<template>
  <div class="ma-0 pa-0">
    <v-menu
      top
      transition="fade-transition"
      :close-on-content-click="false"
      offset-y
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          icon
          dense
          hide-details
          :color="colorIcon"
          v-bind="attrs"
          v-on="on"
          small
          elevation="0"
          style="min-width: 30px; max-width: 30px;"
        >
          <v-icon size="22">mdi-emoticon-happy-outline</v-icon>
        </v-btn>
      </template>

      <v-card class="pt-2 pb-3 px-3" width="320">
        <v-fade-transition>
          <v-progress-linear
            v-if="loading"
            absolute
            top
            indeterminate
            color="orange"
          ></v-progress-linear>
        </v-fade-transition>
        
        <v-row class="px-2 pb-2">
          <v-col
            v-for="emoji in emojis"
            :key="emoji"
            cols="2"
            class="emoji-item text-center"
            @click="selectEmoji(emoji)"
          >
            {{ emoji }}
          </v-col>
        </v-row>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
export default {
  data: () => ({
    loading: false,
    emojis: ["😀", "😂", "😍", "😎", "😜", "🤩", "🥳", "😡", "😢", "👍", "🔥", "❤️"]
  }),
  props: {
    colorIcon: {
      require: false,
      default: "orange"
    }
  },
  methods: {
    selectEmoji(emoji) {
      this.$emit("EmojiPaste", emoji);
    }
  }
};
</script>

<style scoped lang="scss">
.emoji-item {
  font-size: 22px;
  cursor: pointer;
  transition: 0.3s;
  &:hover {
    transform: scale(1.2);
  }
}
</style>
