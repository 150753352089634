<template>
  <div>
    <v-app-bar
      :height="$vuetify.breakpoint.width <= 839 ? '150' : '56'"
      class="header__wrapper"
      :color="$vuetify.theme.dark ? 'grey darken-3' : 'grey lighten-5'"
    >
      <v-row align="center" class="d-flex justify-start">
        <v-col cols="auto" class="px-0 py-0">
          <v-btn icon @click="toggleLeftMenu(!mini)" size="24"
            ><v-icon size="24">mdi-menu</v-icon></v-btn
          >
        </v-col>
        <v-col cols="auto" class="d-flex align-center">
          <img
            @click="$router.push('/').catch(() => {})"
            class="cursor-pointer"
            src="@/assets/layout_img/TaskTrackerLogoName.svg"
          />
        </v-col>
        <v-menu offset-y v-model="showProjects" :close-on-content-click="true">
          <template v-slot:activator="{ on, attrs }">
            <v-btn :style="$vuetify.breakpoint.width >= 780 ? {} : { minWidth: '35px', maxWidth: '35px' }" small outlined color="orange" class="ml-5" v-bind="attrs" v-on="on" @click="showProjects = !showProjects">
              <div v-if="$vuetify.breakpoint.width >= 780">{{ $t("form.Project_lable") }}</div>
              <v-icon v-else>mdi-list-box</v-icon>
            </v-btn>
          </template>
          <v-card style="min-width: 300px; max-height: 300px; overflow-y: auto;">
            <v-list>
              <h4 style="font-size: 18px;" class="ml-4 py-3">{{ $t('home.created_by_me') }}</h4>
              <v-list-item
                v-for="(project, index) in userProjects"
                :key="project.id"
                @click="pushToProject(project)">
                <v-list-item-avatar>
                  <v-img v-if="project.project_media" :src="project.project_media" alt="project image" />
                  <v-avatar v-else size="40" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(project.name) }}</v-avatar>
                </v-list-item-avatar>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :color="project.flag"
                      small
                      class="ml-7 mt-n7 custom-btn-project btn_flag"
                      style="position: absolute;"
                    >
                      <v-icon size="10">mdi-flag-variant</v-icon>
                    </v-btn>
                  </template>
                    <span>{{ project.comment || "--" }}</span>
                </v-tooltip>
                <v-list-item-content>
                  <v-list-item-title>{{ project.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-spacer />
                  <v-list-item-subtitle class="justify-end d-flex mr-5">
                    <div v-if="project.role === 1">
                      <v-icon>mdi-account-cowboy-hat</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.owner") }}
                      </span>
                    </div>
                    <!-- <div v-else-if="project.role === 2">
                      <v-icon>mdi-account-star</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.project_manager") }}
                      </span>
                    </div>
                    <div v-else-if="project.role === 3">
                      <v-icon>mdi-account</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.staff") }}
                      </span>
                    </div> -->
                  </v-list-item-subtitle>
                </v-list-item-action>
              </v-list-item>

              <div v-if="!loadVisibleMyProject" class="observer" v-observer="{ nextPage: loadMoreMyProject }"></div>
            
              <h4 style="font-size: 18px;" class="ml-4 py-3">{{ $t('home.guest_projects') }}</h4>
              <v-list-item
                v-for="(project, index) in guestProjects"
                :key="project.id"
                @click="pushToProject(project)">
                <v-list-item-avatar>
                  <v-img v-if="project.project_media" :src="project.project_media" alt="project image" />
                  <v-avatar v-else size="40" class="orange text--lighten-1" style="font-weight: 700; cursor: default;">{{ getLetters(project.name) }}</v-avatar>
                </v-list-item-avatar>
                <v-tooltip right>
                  <template #activator="{ on, attrs }">
                    <v-btn
                      v-bind="attrs"
                      v-on="on"
                      :color="project.flag"
                      small
                      class="ml-7 mt-n7 custom-btn-project btn_flag"
                      style="position: absolute;"
                    >
                      <v-icon size="10">mdi-flag-variant</v-icon>
                    </v-btn>
                  </template>
                    <span>{{ project.comment || "--" }}</span>
                </v-tooltip>
                <v-list-item-content>
                  <v-list-item-title>{{ project.name }}</v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-spacer />
                  <v-list-item-subtitle class="justify-end d-flex mr-5">
                    <!-- <div v-if="project.role === 1">
                      <v-icon>mdi-account-cowboy-hat</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.owner") }}
                      </span>
                    </div> -->
                    <div v-if="project.role === 2">
                      <v-icon>mdi-account-star</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.project_manager") }}
                      </span>
                    </div>
                    <div v-else-if="project.role === 3">
                      <v-icon>mdi-account</v-icon>
                      <span v-if="$vuetify.breakpoint.smAndUp" class="text-body-2 font-weight-medium ml-1">
                        {{ $t("home.staff") }}
                      </span>
                    </div>
                  </v-list-item-subtitle>
                </v-list-item-action>
              </v-list-item>

              <div v-if="!loadVisibleGuestProject" class="observer" v-observer="{ nextPage: loadMoreGuestProject }"></div>

            </v-list>
          </v-card>
        </v-menu>

        <v-btn :style="$vuetify.breakpoint.width >= 1053 ? {} : { minWidth: '35px', maxWidth: '35px' }" outlined color="orange" small class="ml-3" @click="statisticsTime = true;">
          <v-icon left v-if="$vuetify.breakpoint.width >= 1053">mdi-timeline-text</v-icon>
          <v-icon v-else size="20">mdi-timeline-text</v-icon>
          <div v-if="$vuetify.breakpoint.width >= 1053">{{ $t("btn.timeReport") }}</div>
        </v-btn>

        <v-spacer></v-spacer>
        <v-col cols="auto" class="my-0 mr-0 ml-2 pa-0">
          <v-btn style="max-width: 35px; min-width: 35px;" outlined small color="orange" @click="DeviceDialogVisible = true">
            <v-icon size="18">mdi-monitor-cellphone-star</v-icon>
          </v-btn>
        </v-col>
        <v-col cols="auto">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                text
                outlined
                v-bind="attrs"
                v-on="on"
                elevation="0"
                :ripple="false"
                color="orange"
                @click="totalTaskVisible = true"
                :style="$vuetify.breakpoint.width >= 1053 ? '':'max-width: 60px; min-width: 60px;'"
              >
                <span class="d-flex align-center">
                  <v-icon size="18">mdi-check-circle-outline</v-icon>
                  <span class="ml-1 text-subtitle" v-if="$vuetify.breakpoint.width >= 1053"
                    >{{ $t("form.Tasks") }}: {{ tasksAmount }}</span
                  >
                  <span v-else class="ml-1 text-subtitle"
                    >{{ tasksAmount }}</span
                  >
                </span>
              </v-btn>
            </template>
            <span> {{ $t("form.TotalTaskBtn") }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto" class="ma-0 pa-0">
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn small color="orange" icon v-bind="attrs" v-on="on" @click="VisibleStatisticProjects()">
                <v-icon size="20">mdi-table-arrow-right</v-icon>
              </v-btn>
            </template>
            <span>{{ $t("form.tooltiptableprojects") }}</span>
          </v-tooltip>
        </v-col>
        <v-col cols="auto" class="d-flex align-center">
          <UserDropdown />
        </v-col>
      </v-row>
    </v-app-bar>
    <navigationDrawer />
    <statisticsTimeDialog
      :visible="statisticsTime"
      @close="statisticsTime = false"
      v-if="statisticsTime"
    />
    <DeviceDialog 
      v-if="DeviceDialogVisible"
      :visible="DeviceDialogVisible"
      @close="DeviceDialogVisible = false"
      :project="null"
    />
    <totalTasksDialog 
      :visible="totalTaskVisible"
      v-if="totalTaskVisible"
      @close="totalTaskVisible = false"
    />
      <!-- :project="statisticsTime" -->
    <projectsStatisticDialog 
      v-if="statisticDialog"
      :visible="statisticDialog"
      @close="statisticDialog = false"
    />

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import * as getterTypes from "@/store/modules/auth/types/getters";
import * as mutationTypes from "@/store/modules/auth/types/mutations";
import * as actionTypes from "@/store/modules/sideBarValues/types/actions";
import * as actionType from "@/store/modules/auth/types/actions";
import store from "@/store";

import UserDropdown from "@/components/layout/UserDropdown.vue";
import NavigationDrawer from "@/components/layout/navigationDrawer.vue";
import usersService from "@/services/request/users/usersService";
import projectsService from "@/services/request/projects/projectsService";
import statisticsTimeDialog from "../home/statisticsTimeDialog.vue";
import totalTasksDialog from "../boards/dialogs/totalTasksDialog.vue";
import DeviceDialog from "../boards/dialogs/DeviceDialog.vue";
import projectsStatisticDialog from "../boards/dialogs/projectsStatisticDialog.vue";

export default {
  name: "HeaderComponent",
  components: { NavigationDrawer,projectsStatisticDialog, UserDropdown, projectsService, statisticsTimeDialog, totalTasksDialog, DeviceDialog },
  data: () => ({
    tasksAmount: 0,
    userProjects: [],
    guestProjects: [],
    showProjects: false,
    statisticsTime: false,
    totalTaskVisible: false,
    userCards: [],
    DeviceDialogVisible: false,

    loadVisibleMyProject: false,
    loadVisibleGuestProject: false,

    page_MyProject: 0,
    page_GuestProject: 0,
    statisticDialog: false,
  }),
  mounted() {
    this.fetchCardsAmount();
  },
  methods: {

    async VisibleStatisticProjects() {
      this.statisticDialog = true;
    },

    //Export cvs

    // async ExportTableProjects() {
    //   const ExportProjects = await projectsService.ExportProjectsTable();

    //   if (ExportProjects?.data?.length) {
    //     const headers = ['Name', 'Tasks', 'Bugs', 'P1', 'P2', 'P3', 'P4', 'P5', 'Story Points']
    //       .map(header => this.$t(header));

    //     const rows = ExportProjects.data.map(item => [
    //       item.name,
    //       item.total_tasks,
    //       item.total_bugs,
    //       item.total_p1,
    //       item.total_p2,
    //       item.total_p3,
    //       item.total_p4,
    //       item.total_p5,
    //       item.total_story_points || 0
    //     ].map(value => `"${value}"`).join(';'));

    //     const csvContent = [headers.join(';'), ...rows].join('\n');

    //     const bom = '\uFEFF';
    //     const blob = new Blob([bom, csvContent], { type: 'text/csv;charset=utf-8;' });
    //     const url = URL.createObjectURL(blob);
    //     const link = document.createElement('a');
    //     link.setAttribute('href', url);
    //     link.setAttribute('download', 'projects_export.csv');
    //     document.body.appendChild(link);
    //     link.click();
    //     document.body.removeChild(link);
    //   } else {
    //     console.warn('No data available to export');
    //   }
    // },

    //Export cvs

    loadMoreMyProject() {
      this.page_MyProject++;
      this.MyProjects(this.page_MyProject);
    },
    loadMoreGuestProject() {
      this.page_GuestProject++;
      this.GuestProjects(this.page_GuestProject);
    },
    getLetters(item) {
      let letters = "";
      if (item) {
        letters = item?.trim().charAt(0).toUpperCase();
      }
      return letters;
    },
    async MyProjects(morePage) {
      try {
        const params = {
          page: morePage,
          per_page: 8,
          owner: true,
        };
        const newMyProjects = await projectsService.getList(params);
        if (newMyProjects.length) {
          this.userProjects = [...this.userProjects, ...newMyProjects];
        }
        if(newMyProjects.length < 8) {
          this.loadVisibleMyProject = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async GuestProjects(morePage) {
      try {
        const params = {
          page: morePage,
          per_page: 8,
          owner: false,
        };
        const newGuestProjects = await projectsService.getList(params);
        if (newGuestProjects.length) {
          this.guestProjects = [...this.guestProjects, ...newGuestProjects];
        }
        if(newGuestProjects.length < 8) {
          this.loadVisibleGuestProject = true;
        }
      } catch (e) {
        console.log(e);
      }
    },
    async pushToProject(project) {
      this.$router.push('/').catch(() => {});
      console.log('Обраний проєкт:', project);
      store.dispatch(
        `sideBarValues/${actionTypes.SET_COPIED_UUID}`,
        project.id,
      );
      store.dispatch(
        `sideBarValues/${actionTypes.SET_COPIED_PROJECT}`,
        project,
      );

      const userData = this.getUserData;
      userData.role = project.role;

      await this.updateUserData(userData);

      this.$router.push(
        this.$localize({
          name: "projectInfo",
          params: { id: project.id, name: project.name },
        })
      );
    },
    toggleLeftMenu(param) {
      this.$store.commit(`auth/${mutationTypes.SET_MENU_POSITION}`, param);
    },
    async fetchCardsAmount() {
      try {
        await this.getUserCards();
        const interval = 60000;
        this.intervalFetchUserData = setInterval(() => {
          this.getUserCards();
        }, interval);
      } catch (e) {
        console.log(e);
      }
    },
    async getUserCards() {
      try {
          const getUserTotal = await usersService.getUserCardsTotal();
          this.tasksAmount = getUserTotal.data.toString();
      } catch (error) {
        console.error("Failed to fetch user cards:", error);
      }
    },
    ...mapActions("auth", {
        updateUserData: actionType.USER_UPDATE_DATA,
      }),
  },
  destroyed() {
    clearInterval(this.intervalFetchUserData);
  },
  computed: {
    ...mapGetters("auth", {
      getUserData: getterTypes.GET_USER_DATA,
    }),
    ...mapGetters("auth", {
      menuPosition: getterTypes.GET_MENU_POSITION,
    }),

    mini: {
      get() {
        return this.menuPosition;
      },
      set(mini) {
        return mini;
      },
    },
  },
};
</script>

<style scoped>
.header__wrapper {
  position: relative;
  width: 100%;
}
</style>
